var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"fn24-weight7"},[_vm._v("Digital Assets Hub")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{staticClass:"mx-auto",attrs:{"active-class":"active"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabItems),function(item){return _c('v-tab',{key:item.tab,staticClass:"fn14 mx-auto",staticStyle:{"font-weight":"600","width":"556px"}},[_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(item.tab))])])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabItems),function(item){return _c('v-tab-item',{key:item.tab,staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mr-4",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[(_vm.tab === 0)?_c('stepForm',{attrs:{"dialog":_vm.dialog,"form":_vm.form},on:{"update:form":function($event){_vm.form=$event}}}):_vm._e(),(_vm.tab === 1)?_c('stepFormCategory',{attrs:{"dialog":_vm.dialog,"form":_vm.form},on:{"update:form":function($event){_vm.form=$event}}}):_vm._e()],1)],1)],1),(item.tab === 'Posts')?_c('v-card',{staticClass:"rounded-lg"},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"font-weight":"700"},attrs:{"headers":item.headers,"items":item.items,"search":_vm.search,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.image",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.category",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.type",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.nexgard",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.point",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.view",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.action",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fn14-weight7"},[_vm._v(_vm._s(item.name))]),_c('div',[_vm._v(_vm._s(item.time))])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.image,"width":"150"}})]}},{key:"item.point",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fn14-weight7",staticStyle:{"color":"#384e85"}},[_vm._v(" "+_vm._s(item.point)+" ")])]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fn14-weight7",staticStyle:{"color":"#384e85"}},[_vm._v(" "+_vm._s(item.view)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == true)?_c('v-switch',{attrs:{"readonly":"","inset":"","color":"#1A347F"},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}}):_vm._e(),(item.status == false)?_c('v-switch',{attrs:{"readonly":"","inset":"","color":"#1A347F"},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}}):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"my-2",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"rounded-xl fn12-weight7 mb-2 mx-1",staticStyle:{"color":"#4f4f4f","width":"100px"},attrs:{"dark":"","outlined":""},on:{"click":_vm.UpdateKnowledge}},[_vm._v(" Edit ")]),_c('v-btn',{staticClass:"rounded-xl fn12-weight7 mx-1",staticStyle:{"color":"#4f4f4f","width":"100px"},attrs:{"dark":"","outlined":""},on:{"click":_vm.DeleteKnowledge}},[_vm._v(" Delete ")])],1)]}}],null,true)})],1):_vm._e(),(item.tab === 'Category')?_c('v-card',{attrs:{"elevation":"0"}},[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-treeview',{attrs:{"hoverable":"","open-all":"","open-on-click":"","items":item.items}})],1),_vm._l((item.items),function(item){return _c('v-col',{key:item,attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"rounded-xl fn12-weight7 mx-1",staticStyle:{"color":"#4f4f4f","width":"100px"},attrs:{"dark":"","outlined":""}},[_vm._v(" Edit ")]),_c('v-btn',{staticClass:"rounded-xl fn12-weight7 mx-1",staticStyle:{"color":"#4f4f4f","width":"100px"},attrs:{"dark":"","outlined":""}},[_vm._v(" Delete ")])],1)})],2)],1)]):_vm._e()],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }