<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="fn24-weight7">Digital Assets Hub</div>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tab" active-class="active" class="mx-auto">
          <v-tab
            v-for="item in tabItems"
            :key="item.tab"
            style="font-weight: 600; width: 556px"
            class="fn14 mx-auto"
          >
            <div class="text-left">{{ item.tab }}</div>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabItems" :key="item.tab" class="ma-2">
            <v-col cols="12">
              <v-row>
                <v-col cols="4" class="mr-4">
                  <v-text-field
                    class="mb-4"
                    v-model="search"
                    dense
                    hide-details
                    outlined
                    placeholder="ค้นหา"
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <stepForm
                    :dialog="dialog"
                    :form.sync="form"
                    v-if="tab === 0"
                  />
                  <stepFormCategory
                    :dialog="dialog"
                    :form.sync="form"
                    v-if="tab === 1"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-card v-if="item.tab === 'Posts'" class="rounded-lg">
              <v-data-table
                :headers="item.headers"
                :items="item.items"
                :search="search"
                disable-pagination
                hide-default-footer
                class="elevation-1"
                style="font-weight: 700"
              >
                <template v-slot:header.name="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.image="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.category="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.type="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.nexgard="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.point="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.view="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.status="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.action="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div class="fn14-weight7">{{ item.name }}</div>
                  <div>{{ item.time }}</div>
                </template>
                <template v-slot:[`item.image`]="{ item }">
                  <v-img :src="item.image" width="150" />
                </template>
                <template v-slot:[`item.point`]="{ item }">
                  <div class="fn14-weight7" style="color: #384e85">
                    {{ item.point }}
                  </div>
                </template>
                <template v-slot:[`item.view`]="{ item }">
                  <div class="fn14-weight7" style="color: #384e85">
                    {{ item.view }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-switch
                    v-if="item.status == true"
                    readonly
                    inset
                    color="#1A347F"
                    v-model="item.status"
                  />
                  <v-switch
                    v-if="item.status == false"
                    readonly
                    inset
                    color="#1A347F"
                    v-model="item.status"
                  />
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-row justify="center" class="my-2">
                    <!-- <v-icon @click="Viewbanners(item)">mdi-eye</v-icon> -->
                    <v-btn
                      @click="UpdateKnowledge"
                      dark
                      outlined
                      class="rounded-xl fn12-weight7 mb-2 mx-1"
                      style="color: #4f4f4f; width: 100px"
                    >
                      Edit
                    </v-btn>
                    <v-btn
                      @click="DeleteKnowledge"
                      dark
                      outlined
                      class="rounded-xl fn12-weight7 mx-1"
                      style="color: #4f4f4f; width: 100px"
                    >
                      Delete
                    </v-btn>
                  </v-row>
                </template>
              </v-data-table>
            </v-card>
            <v-card elevation="0" v-if="item.tab === 'Category'">
              <div>
                <v-row>
                  <v-col cols="9">
                    <v-treeview
                      hoverable
                      open-all
                      open-on-click
                      :items="item.items"
                    >
                    </v-treeview>
                  </v-col>
                  <v-col cols="3" v-for="item in item.items" :key="item">
                    <v-btn
                      dark
                      outlined
                      class="rounded-xl fn12-weight7 mx-1"
                      style="color: #4f4f4f; width: 100px"
                    >
                      Edit
                    </v-btn>
                    <v-btn
                      dark
                      outlined
                      class="rounded-xl fn12-weight7 mx-1"
                      style="color: #4f4f4f; width: 100px"
                    >
                      Delete
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
import stepForm from "@/components/Shared/stepFormKnowledge";
import stepFormCategory from "@/components/Shared/stepFormCategoryKnowledge";
export default {
  components: {
    stepForm,
    stepFormCategory,
  },
  data() {
    return {
      items: [],
      model: 1,
      tab: null,
      tabItems: [
        {
          tab: "Posts",
          headers: [
            { text: "Title Name", value: "name", width: "200" },
            { text: "Images", value: "image", align: "center" },
            { text: "Category", value: "category", width: "150" },
            { text: "", value: "type" },
            { text: "Points", value: "point", width: "100" },
            { text: "Views", value: "view", width: "100" },
            { text: "Status", value: "status", width: "100" },
            { text: "Action", value: "action", align: "center" },
          ],
          items: [
            {
              name: "Learning Hub Intro-duction 101 / 2022",
              time: "Sep 8, 2012 at 10:05 PM",
              image: require("@/assets/images/knowledge.png"),
              category: "Digital Marketing Knowledge",
              type: "Basic",
              point: "30",
              view: "2000",
              status: true,
            },
          ],
        },
        {
          tab: "Category",
          items: [
            {
              name: "Digital Marketing Knowledge",
              children: [
                {
                  name: "Basic",
                  // children: [
                  //   { name: "Nexgard Family" },
                  //   { name: "FRONTLINE" },
                  //   { name: "Broadline" },
                  //   { name: "Heartgard" },
                  // ],
                },
                {
                  name: "Advance",
                },
              ],
            },
          ],
        },
      ],
      form: {
        name: "",
        date: new Date().toISOString().substr(0, 10),
        url: "",
        picture_1: "",
        picture_2: "",
        menufrom: false,
        publish: false,
        status: true,
        type: null,
        categoryName: "",
      },

      search: "",
      count: 0,
    };
  },
  created() {
    this.getAlltips();
  },
  methods: {
    async getAlltips() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/tips`
        // 'http://localhost:8081/categories/'
      );
      console.log("getAlltips", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    UpdateKnowledge(val) {
      localStorage.setItem("tipsdata", Encode.encode(val));
      this.$router.push("EditKnowledge");
    },
    ViewKnowledge(val) {
      localStorage.setItem("tipsdata", Encode.encode(val));
      this.$router.push("ViewKnowledge");
    },
    async DeleteKnowledge(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1a347f",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBiAdmin"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/tipszzz/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAlltips();
        }
      });
    },
    goToCreate() {
      this.$router.push("createtips");
    },
  },
};
</script>

<style scoped>
.active {
  color: #1a347f !important;
  background-color: #edefff !important;
}
.v-table td tr th:hover:not(.v-table__expanded__content) {
  background: rgb(255, 255, 255) !important;
}
</style>
